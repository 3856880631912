import classes from './style.module.scss';
import classNames from "classnames"
import {C_Nav_Sub_Element} from "../c_Nav_Sub_Element";
import {sizes} from "../../../data/sizes";

export const C_Nav_Sub_List = ({className, item, toggle, setToggle, el} : {className?: any, item: number, toggle: boolean, setToggle: Function; el: {section_links: any}}): JSX.Element => {
    const cls = classNames(classes.root, {[classes.secondLvlMenuElWrapperActive]:toggle && el.section_links.length !== 0, [className]: className});

    return (
        <div
            key={"list" + item}
            className={cls}
            onMouseOver={() => window.innerWidth >= sizes.widthDesktopSm ? setToggle(true): null}
            onMouseOut={() => window.innerWidth >= sizes.widthDesktopSm ? setToggle(false): null}
        >
            <ul
                key={"list" + item}
                className={classNames(classes.secondLvlMenuEl)}

            >
                {el.section_links.length !== 0 && el.section_links.map((el: any, i: number) => {
                    return <C_Nav_Sub_Element
                        el={el}
                        i={i}
                        key={el+i}
                        item_key={el+i}
                    />
                })}
            </ul>
        </div>
    )
}