import classes from './style.module.scss';
import classNames from "classnames";

interface Contacts {
  text: string,
  link: string
}

export const C_PhoneContacts = ({className, contacts}: {className?: any, contacts: Contacts[]}):JSX.Element => {
  const cls = classNames(classes.root, {[className]: className});

    return (
      <div className={cls}>
        <a href={`${contacts[0].link}`}>{contacts[0].text}</a>
        <a href={`${contacts[1].link}`}>{contacts[1].text}</a>
      </div>
    )
}