import classes from './style.module.scss';
import classNames from "classnames";
import {C_Nav_Element} from "../c_Nav_Element/index";

export const C_Nav_List = ({className, data} : {className?: any; data?: any}):JSX.Element => {
    const cls = classNames(classes.root, {[className]: className});

    const {links} = data

    return (
        <ul className={cls}>
            {links ? links.map((el: any, item: number) => {
                return <C_Nav_Element
                    el={el}
                    item={item}
                    key={el+item}
                />
            }): null}
        </ul>
    )
}