import classes from './style.module.scss';
import classNames from "classnames";
import {useContext, useEffect, useState} from "react";
import {C_Nav_Sub_List} from "../c_Nav_Sub_List";
import {sizes} from "../../../data/sizes";
import {Context} from "../../../library/";


export const C_Nav_Element = ({className, item, el, _key} : {className?: any, item: number, el: {text: string, anchor: string, link: string, section_links: Array<string> }, _key?: number}): JSX.Element => {

    const cls = classNames(classes.root, {[className]: className});
    const {menu} = useContext(Context);
    const [menuState, setMenuState ] = menu;
    const [toggle, setToggle] = useState<boolean>(false);
    const {text, link, section_links} = el;

    useEffect(() => {
        return () => {
           if(menuState) setToggle(false);
        };
    }, [menuState]);

    return (
        <li key={_key} className={cls} onClick={section_links.length !== 0 ? undefined : () => {
            setMenuState(false)
        }}>
            <a href={link && link !== 'contacts' ? `https://bc-stone.ru/#${link}` : link === 'contacts' ? `/#${link}` : link}>
                <span className={classNames({[classes.chevronWrapper]: toggle && section_links.length !== 0})}
                      onMouseOver={() => window.innerWidth >= sizes.widthDesktopSm ? setToggle(true) : null}
                      onMouseOut={() => window.innerWidth >= sizes.widthDesktopSm ? setToggle(prev => false) : null}
                      onClick={() => window.innerWidth < sizes.widthDesktopSm ? setToggle(prev => !prev) : null}
                      onTouchEnd={() => window.innerWidth >= sizes.widthDesktopSm ? setToggle(prev => !prev) : null}
                >
                    <span className={classes.firstLvlMenuName}>
                        {text}
                    </span>
                    {section_links.length !== 0 && <div
                        className={classNames({
                            [classes.chevron]: section_links.length !== 0,
                            [classes.chevronActive]: toggle && section_links.length !== 0
                        })}
                    />}
                </span>
            </a>

            <C_Nav_Sub_List
                item={item}
                toggle={toggle}
                setToggle={setToggle}
                el={el}
            />
        </li>
    )
}