import classes from './style.module.scss';
import classNames from "classnames";
import { C_Navigation } from "../s_Menu/c_Navigation/index";
import {C_ContactsMenu} from './c_ContactsMenu'
import {useRef} from "react";
import menuOnTop from "../../hooks/MenuOnTop";
import { C_LogoStone } from '@/src/components/c_LogoStone';

interface Menu {
    submit_btn: {
        text: string,
        link: string
    }
    phone: {
        text: string,
        link: string
    }
    whatsapp_link: {
        text: string,
        link: string
    },
    links: {
        text: string,
        link: string | null,
        section_link: {
            text: string,
            link: string,
            color: string,
            isNewPage: boolean,
        }[] | []
    }
}

export const S_Menu = ({className, data}: {className?: any, data: Menu}): JSX.Element => {
    const topMenuEl = useRef(null);
    const cls = classNames(classes.root, {[classes.menuOnTop]: menuOnTop(topMenuEl), [className]: className});
    return (
        <div className={cls} ref={topMenuEl}>
            <C_LogoStone className={classes.C_Logo_Menu} />
            <C_Navigation className={classes.C_Navigation} data={data}/>
            <C_ContactsMenu className={classes.C_ContactsMenu} data={data} />
        </div>
    )
}