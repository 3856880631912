import React, { RefObject } from 'react';
import classes from './style.module.scss';
import classNames from "classnames";
import {useContext, useRef} from "react";
import {Context} from "../../library";
import {sizes} from "../../data/sizes";
import Link from 'next/link';

interface MainButton {
    className?: any,
    text: string,
    onClick?: Function,
    mode?: boolean,
    link?: string,
    disabled?: boolean,
    isError?: boolean,
    path?: boolean,
    form?:boolean
}

export const C_MainButton = ({className, text, onClick, mode = false, link, disabled = false, isError, path, form} : MainButton):JSX.Element => {
    const btnEl: any = useRef(null) ;

    const spanEl: any = useRef(null);
    const cls = classNames(
        classes.root,
        {[className]: className,
        [classes.mode]: mode,
        [classes.error]: isError,
        [classes.path]: path,
        [classes.form]: form}
    );
    const {size}  = useContext(Context);
    const {width} = size;

        return (
            <React.Fragment>

                {onClick || form ?
                    <button
                        type={form ? 'submit' : 'button'}
                        ref={btnEl}
                        className={cls}
                        disabled={disabled}
                        onClick={(e) => onClick ? onClick(e) : null}
                        onMouseOver={(e) => {
                            if (isError) {
                                let rect = btnEl.current.getBoundingClientRect();
                                let xBlockPercent = 0;
                                let yBlockPercent = 0;
                                xBlockPercent = Math.abs((e.pageX - (rect.x + pageXOffset)) / (btnEl.current.clientWidth / 100))
                                yBlockPercent = Math.abs((e.pageY - (rect.y + pageYOffset)) / (btnEl.current.clientHeight / 100))

                                spanEl.current.style.left = xBlockPercent + "%";
                                spanEl.current.style.top = yBlockPercent + "%";
                            } else {

                                    let rect = btnEl.current.getBoundingClientRect();
                                    let xBlockPercent = 0;
                                    let yBlockPercent = 0;
                                    xBlockPercent = Math.abs((e.pageX - (rect.x + pageXOffset)) / (btnEl.current.clientWidth / 100))
                                    yBlockPercent = Math.abs((e.pageY - (rect.y + pageYOffset)) / (btnEl.current.clientHeight / 100))

                                    spanEl.current.style.left = xBlockPercent + "%";
                                    spanEl.current.style.top = yBlockPercent + "%";

                            }
                        }}
                        onMouseOut={(e) => {

                                let rect = btnEl.current.getBoundingClientRect();
                                let xBlockPercent = 0;
                                let yBlockPercent = 0;
                                xBlockPercent = Math.abs((e.pageX - (rect.x + pageXOffset)) / (btnEl.current.clientWidth / 100))
                                yBlockPercent = Math.abs((e.pageY - (rect.y + pageYOffset)) / (btnEl.current.clientHeight / 100))

                                spanEl.current.style.left = xBlockPercent + "%";
                                spanEl.current.style.top = yBlockPercent + "%";

                        }}
                    >
                        <span ref={spanEl} className={classes.span}/>
                        <span className={classes.text}>{text}</span>
                    </button>
                    :

                    !isError ?
                        <a
                            target={'_blank'}
                            ref={btnEl}
                            className={cls}
                            href={link}
                            onMouseOver={(e) => {
                                if (isError) {
                                    let rect = btnEl.current.getBoundingClientRect();
                                    let xBlockPercent = 0;
                                    let yBlockPercent = 0;
                                    xBlockPercent = Math.abs((e.pageX - (rect.x + pageXOffset)) / (btnEl.current.clientWidth / 100))
                                    yBlockPercent = Math.abs((e.pageY - (rect.y + pageYOffset)) / (btnEl.current.clientHeight / 100))

                                    spanEl.current.style.left = xBlockPercent + "%";
                                    spanEl.current.style.top = yBlockPercent + "%";
                                } else {

                                        let rect = btnEl.current.getBoundingClientRect();
                                        let xBlockPercent = 0;
                                        let yBlockPercent = 0;
                                        xBlockPercent = Math.abs((e.pageX - (rect.x + pageXOffset)) / (btnEl.current.clientWidth / 100))
                                        yBlockPercent = Math.abs((e.pageY - (rect.y + pageYOffset)) / (btnEl.current.clientHeight / 100))

                                        spanEl.current.style.left = xBlockPercent + "%";
                                        spanEl.current.style.top = yBlockPercent + "%";

                                }
                            }}
                            onMouseOut={(e) => {

                                    let rect = btnEl.current.getBoundingClientRect();
                                    let xBlockPercent = 0;
                                    let yBlockPercent = 0;
                                    xBlockPercent = Math.abs((e.pageX - (rect.x + pageXOffset)) / (btnEl.current.clientWidth / 100))
                                    yBlockPercent = Math.abs((e.pageY - (rect.y + pageYOffset)) / (btnEl.current.clientHeight / 100))

                                    spanEl.current.style.left = xBlockPercent + "%";
                                    spanEl.current.style.top = yBlockPercent + "%";

                            }}
                        >
                            <span ref={spanEl} className={classes.span}/>
                            <span className={classes.text}>{text}</span>
                        </a>
                    :
                    <Link href={`${link}`} legacyBehavior>
                        <a
                            ref={btnEl}
                            className={cls}
                            // href={`${link}`}
                            onMouseOver={(e) => {
                                if (isError) {
                                    let rect = btnEl.current.getBoundingClientRect();
                                    let xBlockPercent = 0;
                                    let yBlockPercent = 0;
                                    xBlockPercent = Math.abs((e.pageX - (rect.x + pageXOffset)) / (btnEl.current.clientWidth / 100))
                                    yBlockPercent = Math.abs((e.pageY - (rect.y + pageYOffset)) / (btnEl.current.clientHeight / 100))

                                    spanEl.current.style.left = xBlockPercent + "%";
                                    spanEl.current.style.top = yBlockPercent + "%";
                                } else {
                                        let rect = btnEl.current.getBoundingClientRect();
                                        let xBlockPercent = 0;
                                        let yBlockPercent = 0;
                                        xBlockPercent = Math.abs((e.pageX - (rect.x + pageXOffset)) / (btnEl.current.clientWidth / 100))
                                        yBlockPercent = Math.abs((e.pageY - (rect.y + pageYOffset)) / (btnEl.current.clientHeight / 100))

                                        spanEl.current.style.left = xBlockPercent + "%";
                                        spanEl.current.style.top = yBlockPercent + "%";

                                }
                            }}
                            onMouseOut={(e) => {

                                    let rect = btnEl.current.getBoundingClientRect();
                                    let xBlockPercent = 0;
                                    let yBlockPercent = 0;
                                    xBlockPercent = Math.abs((e.pageX - (rect.x + pageXOffset)) / (btnEl.current.clientWidth / 100))
                                    yBlockPercent = Math.abs((e.pageY - (rect.y + pageYOffset)) / (btnEl.current.clientHeight / 100))

                                    spanEl.current.style.left = xBlockPercent + "%";
                                    spanEl.current.style.top = yBlockPercent + "%";

                            }}
                        >
                            <span ref={spanEl} className={classes.span}/>
                            <span className={classes.text}>{text}</span>
                        </a>
                    </Link>
                }
            </React.Fragment>
        )
}
