import React, {useEffect} from 'react';

export const Context = React.createContext();
//Сохранение UTM-меток при переходе с сайта на сайт
export const UtmAdd = () => {
    useEffect(() => {
        //utm метки

        (function () {
            let domainsToDecorate = [
                'stone-svl.ru',
                'stone-len.ru',
                'stone-tws.ru',
            ];

            let links = document.querySelectorAll('a');


            for (let linkIndex = 0; linkIndex < links.length; linkIndex++) {
                for (let domainIndex = 0; domainIndex < domainsToDecorate.length; domainIndex++) {
                    if (links[linkIndex].href.indexOf(domainsToDecorate[domainIndex]) > -1 && links[linkIndex].href.indexOf("#") === -1 && links[linkIndex].href.indexOf(window.location.search) === -1) {
                        links[linkIndex].href = decorateUrl(links[linkIndex].href);
                    }
                }
            }

            function decorateUrl(urlToDecorate) {

                const regex = /\?*/g;

                if (window.location.search.match(regex) !== null) {
                    return urlToDecorate.slice(0, -1) + window.location.search + `&ref=${window.location.hostname.replace('www.', '')}`
                } else {
                    return urlToDecorate
                }
            }
        })();

    }, [])
}
