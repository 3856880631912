import Link from 'next/link';
import classes from './style.module.scss';
import classNames from "classnames";

export const C_Logo = ({className} : {className?: any} ):JSX.Element => {
    const cls = classNames(classes.root, {[className]: className});
    return (
        <Link legacyBehavior href={"#!"} rel="noreferrer" target={"_self"}>
            <a className={cls}/>
        </Link>
    )
}