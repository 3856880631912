import classes from './style.module.scss';
import classNames from "classnames";
import { C_PhoneContacts } from '../c_PhoneContacts';
import { C_Address } from '../c_Address';

export const C_FooterContacts = ({className, contacts, address, sales_number, develop}: {className?: any, contacts: {text: string, link: string}[], address: string, sales_number: string, develop: {text: string, mail: string}}) => {

  const cls = classNames(classes.root, {[className]: className});

    return (
      <div className={cls}>
        <C_PhoneContacts contacts={contacts} />
        <C_Address address={address} sales_number={sales_number} develop={develop}/>
      </div>
    )
}