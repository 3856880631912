import classes from './style.module.scss';
import classNames from "classnames";

export const C_ContactsMenu = ({className, data} : {className?: any, data?: any}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });

    const {phone, whatsapp_link} = data

    return (
        <div className={cls}>
            <a href={"#"} className={classes.callMe} />
            <a href={whatsapp_link.link} target={"_blank"} rel="noreferrer" className={classes.whatsapp} />
            <a href={phone.link} className={classes.phone} dangerouslySetInnerHTML={{__html: phone.text}}></a>
        </div>
    )
}
