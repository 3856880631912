import classes from './style.module.scss';
import classNames from "classnames";
import {useContext} from "react";
import {Context} from "../../../library";
import Image from 'next/image';

interface ListElement {
    text: string,
    color: string,
    link: string,
    anchor: string,
    isNewPage: string,
    logoMobile: any,
    logoTablet: any,
    logoDesktop: any
}

export const C_Nav_Sub_Element = ({className, el, i, item_key} : {className?: any, el: ListElement, i: number, item_key: number}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className});
    const {logoMobile, logoTablet, logoDesktop, color, link, isNewPage} = el;

    const {size, menu} = useContext(Context);
    const [menuState, setMenuState ] = menu;
    const {width} = size;

    return (
        <li
            className={cls}
            key={item_key}
        >{link.length != 0 ?
            (<a
                href={link[0] === '/' ? `https://bc-stone.ru${link}` : `${link}`}
                target={isNewPage === "false" ? "_self" : "_blank"}
                rel="noreferrer"
                className={classes.projectName}
                onClick={()=> setMenuState(false)}>
                {
                        width >= 1920
                        ?
                            <Image width={logoDesktop?.data?.attributes?.width} height={logoDesktop?.data?.attributes?.height} src={logoDesktop?.data?.attributes?.url} alt="логотип проекта"></Image>
                        :
                        width < 768 ?
                                <Image width={logoMobile?.data?.attributes?.width} height={logoMobile?.data?.attributes?.height} src={logoMobile?.data?.attributes?.url} alt="логотип проекта"></Image>
                            :
                                <Image width={logoTablet?.data?.attributes?.width} height={logoTablet?.data?.attributes?.height} src={logoTablet?.data?.attributes?.url} alt="логотип проекта"></Image>
                        }
                <span
                    className={classes.underline}
                    style={{backgroundColor: `${color}`}}
                />
            </a>) : (
                <a
                    href={`/`}
                    className={classes.projectName}
                    onClick={(e) => {
                        e.preventDefault();
                        setMenuState(false)
                    }}>
                    {
                        width >= 1920
                        ?
                            <Image width={logoDesktop?.data?.attributes?.width} height={logoDesktop?.data?.attributes?.height} src={logoDesktop?.data?.attributes?.url} alt="логотип проекта"></Image>
                        :
                        width < 768 ?
                                <Image width={logoMobile?.data?.attributes?.width} height={logoMobile?.data?.attributes?.height} src={logoMobile?.data?.attributes?.url} alt="логотип проекта"></Image>
                            :
                                <Image width={logoTablet?.data?.attributes?.width} height={logoTablet?.data?.attributes?.height} src={logoTablet?.data?.attributes?.url} alt="логотип проекта"></Image>
                        }
                    <span
                        className={classes.underline}
                        style={{backgroundColor: `${color}`}}
                    />
                </a>
            )}
        </li>
    )
}