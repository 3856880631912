import classNames from 'classnames';
import classes from './style.module.scss';
import Link from 'next/link';

interface Copyright {
  name: string,
  author: string,
  description: string
}

export const C_Copyright = ({className, privacyCorporation, privacyText, privacyLink} : {className?: any, privacyCorporation: string, privacyText: string, privacyLink: {text: string, link: string}}):JSX.Element => {

  const cls = classNames(classes.root, {[className]: className});

  return (
      <div className={cls}>
        <div>
          <Link legacyBehavior href={`${privacyLink.link}`} rel="noreferrer">
            <a>{privacyLink.text}</a>
          </Link>
          </div>
          <div>{privacyCorporation}</div>
          <div>{privacyText}</div>
      </div>
  )
}