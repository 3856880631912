import classNames from 'classnames';
import classes from './style.module.scss';

interface Address {
  className?: any,
  address: string,
  sales_number: string,
  develop: {
    text: string,
    mail: string
  }
}

export const C_Address = ({className, address, sales_number, develop}: Address): JSX.Element => {

  const cls = classNames(classes.root, {[className]: className});

  return (
      <div className={cls}>
          <p dangerouslySetInnerHTML={{__html: address}}></p>
          <p dangerouslySetInnerHTML={{__html: sales_number}}></p>
          <p>
            <span dangerouslySetInnerHTML={{__html: develop.text}}></span>
            <a href={`mailto:${develop.mail}`} dangerouslySetInnerHTML={{__html: develop.mail}}></a>
          </p>
      </div>
  )
}