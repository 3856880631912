import classes from './style.module.scss';
import classNames from "classnames";
import { C_FooterContacts } from '@/src/sections/s_Footer/с_FooterContacts';
import { C_Copyright } from './c_Copyright';
import { C_Logo } from '../../components/c_Logo';
import { C_FooterNavigation } from '@/src/sections/s_Footer/c_FooterNavigation';
import { C_Social } from '@/src/sections/s_Footer/c_Social';
import { C_OnTopBtn} from '../../components/c_OnTopBtn/index'
import { C_CallUsBtn } from '@/src/components/c_CallUsBtn';

interface FooterData {
    contacts: {
        text: string,
        link: string
    }[],
    address: string,
    time: string,
    privacy: string,
    privacy_link: {
        text: string,
        link: string
    },
    privacy_corporation: string,
    slogan: string,
    len_data: {
        slogan: string,
        develop: {
            text: string,
            mail: string
        }
    }
}

export const S_Footer = ({className, data} : {className?: any, data: FooterData}): JSX.Element => {
    const cls = classNames(classes.root, {[className]: className });

    const {contacts, address, time, privacy, privacy_link, privacy_corporation, len_data} = data

    return (
      <div className={classes.wrapRoot} id={'contacts'}>
          <div className={cls}>
                <div className={classes.footer_wrapper}>
                    <h2 dangerouslySetInnerHTML={{__html: len_data.slogan}}></h2>
                        <C_FooterContacts contacts={contacts} address={address} sales_number={time} develop={len_data.develop}/>
                        <C_FooterNavigation />
                        <C_Social />
                        <div className={classes.copyright_wrapper}>
                            <div className={classes.logo_wrapper}>
                                <C_Logo className={classes.logo_footer}/>
                            </div>
                            <C_Copyright privacyCorporation={privacy_corporation} privacyText={privacy} privacyLink={privacy_link}/>
                        </div>
                </div>
                <C_OnTopBtn className={classes.footer_ontop_btn}/>
                <C_CallUsBtn />
          </div>
      </div>
  )
}