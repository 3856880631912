import './../styles/globals.scss';
import type {AppProps} from 'next/app';
import {Context, UtmAdd} from "@/src/library";
import React, {useEffect, useState} from 'react';
import useWindowSize from "../src/hooks/useWindowSize";
import {S_Footer} from '@/src/sections/s_Footer';
import {S_Menu} from '@/src/sections/s_Menu';
import TagManager from 'react-gtm-module';
import App from "next/app"
import * as fs from "fs/promises";

export default function Leninskiy({Component, pageProps, commonData}: AppProps | any) {
    const size = useWindowSize();
    const [menuState, setMenuState] = useState<boolean>(false);
    const [popupState, setPopupState] = useState(false);
    const [leadState, setLeadState] = useState(false);
    UtmAdd();
    useEffect(() => {
        TagManager.initialize({gtmId: 'GTM-N8CSF47'});
    }, []);

    const {menu_block, footer_block} = commonData.data.attributes

    return (
        <Context.Provider value={{
            size: size,
            menu: [menuState, setMenuState],
            popup: [popupState, setPopupState],
            lead: [leadState, setLeadState]
        }}>
            <S_Menu data={menu_block}/>
            <Component {...pageProps} />
            <S_Footer data={footer_block}/>
        </Context.Provider>
    )
}

Leninskiy.getInitialProps = async (appContext: any) => {
    const appProps = await  App.getInitialProps(appContext);
    const filePath = "./db.json";
    const fileContent = await fs.readFile(filePath, 'utf8');
    const commonData = JSON.parse(fileContent).common;

    return {...appProps, commonData}
}